import React, { useState, useEffect } from "react";
import "./Results.css";
import logo from "../img/udemy.png";
import Selected_course from "./Selected_course";
import { Route, Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

const Results = ({ courses }) => {
  // const apiEndpoint = "https://jsonplaceholder.typicode.com/posts";
  // const userFetchResponse = useFetch(apiEndpoint, {
  //   isLoading: true,
  //   data: null,
  // });

  // if (!userFetchResponse.data || userFetchResponse.isLoading) {
  //   return "Loading...";
  // }

  // function useFetch(url, defaultResponse) {
  //   const [data, setData] = useState(defaultResponse);

  //   async function getDataFromAPI(url) {
  //     try {
  //       const res = await fetch(url);
  //       const data = await res.json();
  //       setData({
  //         isLoading: false,
  //         data,
  //       });
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }

  //   useEffect(() => {
  //     getDataFromAPI(url);
  //   }, []);

  //   return data;
  // }

  // const arrayPosts = userFetchResponse.data;
  // console.log("UDEMY COURSES:", courses[0]);

  return (
    <div className="Results">
      {!Array.isArray(courses.results || courses.results === undefined) ? (
        <div class="loader"></div>
      ) : (
        <div className="Divtop">
          <div className="Div-Result-Num">
            <p>{courses.count} courses found</p>
          </div>
        </div>
      )}
      <div className="Structure">
        <div className="Result-list">
          {!Array.isArray(courses.results || courses.results === undefined) ? (
            ""
          ) : (
            <ul>
              {courses.results.map((course, i) => (
                <Fade left>
                  <Link to={`/search/${course.id}`} key={"link" + i}>
                    <div className="Course-Card" key={"div" + i}>
                      <img
                        className="Image-Card"
                        alt={`company: ${course.title}`}
                        src={logo}
                        key={"ImageCard" + i}
                      />
                      <p className="Course-Title" key={"title" + i}>
                        {course.title}
                      </p>{" "}
                      <p className="Course-Body" key={"body" + i}>
                        {course.price}
                      </p>
                    </div>
                  </Link>
                </Fade>
              ))}
            </ul>
          )}
        </div>
        {!Array.isArray(courses.results || courses.results === undefined) ? (
          ""
        ) : (
          <Route path="/search/:postid">
            <Zoom>
              <Selected_course courses={courses.results} />
            </Zoom>
          </Route>
        )}
      </div>
    </div>
  );
};

export default Results;
