import React, { useState } from "react";
import logo from "../img/dexnialogo.png";
import { NavLink, useHistory } from "react-router-dom";

const Header1 = (props) => {
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };

  const resetInputField = () => {
    setSearchValue("");
  };

  const callSearchFunction = (e) => {
    e.preventDefault();
    let mySearch = myTrim(searchValue);
    setSearchValue(mySearch);
    props.search(searchValue);
    resetInputField();
    history.push("/search/" + searchValue + "");
  };

  const backHome = () => {
    props.initialize();
  };

  function myTrim(x) {
    return x.replace(/^\s+|\s+$/gm, "");
  }

  return (
    <div className="Header">
      <div>
        {/* <h1 className="Headerlogo">DdexniA</h1> */}
        <NavLink to={"/"}>
          <img
            className="Dexnia-logo"
            alt="Dexnia Logo"
            src={logo}
            onClick={backHome}
          />
        </NavLink>
      </div>
      <div className="Search-div-header">
        <form>
          <input
            className="Bar-header"
            placeholder=" What do you want to learn today?"
            value={searchValue}
            onChange={handleSearchInputChanges}
            type="text"
          />
          <input
            className="Button-header"
            type="submit"
            value=" Search "
            onClick={callSearchFunction}
          />
        </form>
      </div>
    </div>
  );
};

export default Header1;
