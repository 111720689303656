import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./Selected_course.css";
import logo from "../img/udemylogo.png";

const Selected_course = (courses) => {
  let { postid } = useParams();
  let course = { title: "", headline: "", id: 0 };
  try {
    if (isNaN(postid)) {
      postid = courses.courses[0].id;
      course = courses.courses.find((course) => course.id === postid);
    } else {
      postid = parseInt(postid);
      course = courses.courses.find((course) => course.id === postid);
    }
  } catch {}

  return (
    // <div className="DivSelected">
    //   {!courses ? (
    //     "Loading..."
    //   ) : (
    // <div className="Course-Selected">
    //   <div className="Course-Selected-Top">
    //     <img
    //       className="Selected-Image"
    //       alt={`company: ${course.title}`}
    //       src={logo}
    //     />
    //     <p className="Course-Selected-Title">{course.title}</p>
    //   </div>
    //   <div className="Course-Selected-Middle">
    //     <img
    //       className="Course-Image"
    //       alt={`company: ${course.title}`}
    //       src={course.image_125_H}
    //     />

    //     <p className="Course-Selected-Body">{course.headline}</p>
    //   </div>

    //   <div className="Course-Selected-Bottom">
    //     <p className="Share">Share</p>
    //   </div>
    // </div>
    <div className="Course-Selected">
      <img
        className="Course-Image"
        alt={`company: ${course.title}`}
        src={course.image_480x270}
      />
      <p className="Course-Selected-Title">{course.title}</p>

      <p className="Course-Selected-Body">{course.headline}</p>
      <p className="Course-Selected-Price">{course.price}</p>
      <img className="Company-Logo" alt="logo course" src={logo} />
      <a className="Course-Link" href={`https://www.udemy.com${course.url}`}>
        Go to course
      </a>

      {/* <div className="Course-Selected-Bottom">
            <p className="Share">Share</p>
          </div> */}
    </div>
    //   )}
    // </div>
  );
};

export default Selected_course;
