import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

const topics = [
  "Data Science",
  "Cybersecurity",
  "Digital Marketing",
  "AI",
  "Graphic Design",
  "Gamification",
  "Video Edit",
  "UI/UX",
  "Cloud Architecture",
];

const Search = (props) => {
  let history = useHistory();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    let mySearch = myTrim(data.valueSearch);
    setSearchValue(mySearch);
    // console.log("onSubmit", data.valueSearch, " Trim ", mySearch);

    if (mySearch.length > 35) {
      alert("Sorry your input is too long");
    } else {
      if (data && mySearch !== "") {
        props.search(searchValue);
        resetInputField();
        history.push("/search/" + searchValue + "");
      }
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChanges = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const resetInputField = () => {
    setSearchValue("");
  };

  // const callSearchFunction = (e) => {
  //   e.preventDefault();
  //   console.log("Submited");
  //   props.search(searchValue);
  //   resetInputField();
  //
  // };

  const topicSubmit = async (e) => {
    setSearchValue(e.target.value);
    props.search(e.target.value);
  };

  function myTrim(x) {
    return x.replace(/^\s+|\s+$/gm, "");
  }

  return (
    <div className="Search">
      <div className="SearchTitle">
        <Bounce left>
          <p className="Question">What do you want to learn today?</p>
        </Bounce>
        <Slide bottom>
          <div className="Searchbar">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className="Bar"
                placeholder="Type here..."
                name="valueSearch"
                value={searchValue}
                onChange={handleSearchInputChanges}
                type="text"
                ref={register}
              />

              <input className="ButtonSearch" type="submit" value="Search" />
            </form>
          </div>
          <div className="TrendingList">
            <p>Trending topics</p>
            {topics.map((topic, i) => (
              <Link to={`/search/${topic}`}>
                <button
                  className="ButtonTopic"
                  value={topic}
                  onClick={topicSubmit}
                >
                  {topic}
                </button>
              </Link>
            ))}
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Search;
