import React from "react";
// import logo from "../img/dexnia1.png";
import logo from "../img/dexnialogo.png";

function Header() {
  return (
    <div className="Header">
      {/* <h1 className="Headerlogo">DdexniA</h1> */}
      <img className="Dexnia-logo" alt="Dexnia Logo" src={logo} />
    </div>
  );
}

export default Header;
