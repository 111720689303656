import React from "react";
import "./Dexnia_homepage.css";
import imagehome from "../img/classimg.png";
import Fade from "react-reveal/Fade";

const Dexnia_homepage = () => {
  return (
    <div className="DexniaHomepage">
      {/* <div className="Searchdiv">
        <div className="Question1">
          <h1>What do you want to learn?</h1>
          <h2>Coming soon...</h2>
        </div>
      </div> */}
      <div className="Presentation">
        <Fade left>
          <div className="Who">
            <h2>Why Dexnia?</h2>
            <p>
              A faster way to find the best online courses from the global
              leaders at e-learning. No more going through countless websites.
              Find them all right here, compare them and choose the ones best
              suited for you. Start learning relevant skills today.
            </p>
            {/* <ul>
            <li>Access to a large variety of courses to choose from.</li>
            <li>Freedom to direct your own study schedule.</li>
            <li>It doesn't cost as much, even for free.</li>
            <li>Confortable learning environment.</li>
            <li>It's eco-friendly.</li>
            <li>Career advancement.</li>
            <li>Meet with a comunity with the same interests.</li>
            <li>You can review course materials repeatedly.</li>
            <li>Get your score immediately.</li>
          </ul> */}
          </div>
        </Fade>
        <Fade right>
          <div>
            <img className="People" alt="people studying" src={imagehome} />{" "}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Dexnia_homepage;
