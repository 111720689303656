import React, { useReducer } from "react";
import "./App.css";
import Header from "./components/Header";
import Header1 from "./components/Header1";
import Footer from "./components/Footer";
import Search from "./components/Search";
import Dexnia_homepage from "./components/Dexnia_homepage";
import * as request from "superagent";

import Results from "./components/Results";
// import Homepage from "./components/Homepage";
import { Route, Switch } from "react-router-dom";

// const url = "http://localhost:4000/search";

const initialState = {
  loading: true,
  courses: [],
  errorMessage: null,
  searching: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH_COURSES_REQUEST":
      return {
        ...state,
        loading: true,
        errorMessage: null,
        searching: true,
      };
    case "SEARCH_COURSES_SUCCESS":
      return {
        ...state,
        loading: false,
        courses: action.payload,
        searching: true,
      };
    case "SEARCH_COURSES_FAILURE":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
        searching: true,
      };
    case "INITIALIZE_COURSES":
      return {
        ...state,
        loading: false,
        courses: [],
        searching: false,
      };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   fetch(COURSE_API_URL)
  //     .then((response) => response.json())
  //     .then((jsonResponse) => {
  //       dispatch({
  //         type: "SEARCH_COURSES_SUCCESS",
  //         payload: jsonResponse,
  //       });
  //     });
  // }, []);

  const search = async (searchValue) => {
    dispatch({
      type: "INITIALIZE_COURSES",
    });
    dispatch({
      type: "SEARCH_COURSES_REQUEST",
    });
    const newSearchValue = searchValue.replace("/", "-");
    console.log("Search value is ", newSearchValue);

    // fetch(
    //   `https://www.udemy.com/api-2.0/courses/?search=${searchValue}&language=en&ordering=most-reviewed&ratings=4`,
    //   options
    // )
    //   .then((response) => response.json())
    //   .then((jsonResponse) => {
    //     console.log("JSON", jsonResponse);
    //     dispatch({
    //       type: "SEARCH_COURSES_SUCCESS",
    //       payload: jsonResponse,
    //     });
    //   });

    // document.location = "/search/" + searchValue;
    // window.location.href = "search/" + searchValue;

    await request
      .get(
        `https://us-central1-dexnia-db98e.cloudfunctions.net/widgets/search/${newSearchValue}`
      )
      .then((response) => {
        // console.log(response.body);
        dispatch({
          type: "SEARCH_COURSES_SUCCESS",
          payload: response.body,
        });
        dispatch(response.body);
      })
      .catch((error) => {
        console.log("Something is wrong with the conection");
        console.log(error);
      });

    // fetch(url)
    //   .then((response) => response.json())
    //   .then((jsonResponse) => {
    //     console.log("JSON", jsonResponse);
    //     dispatch({
    //       type: "SEARCH_COURSES_SUCCESS",
    //       payload: jsonResponse,
    //     });
    //   });
  };

  const initialize = () => {
    dispatch({
      type: "INITIALIZE_COURSES",
    });
  };

  const { courses } = state;
  // const { courses, errorMessage, loading, searching } = state;
  // console.log("courses", courses, "loading", loading, "searching", searching);
  return (
    <div className="App">
      <Switch>
        {/* {!searching ? ( */}
        <Route path="/" exact>
          <Header />
          <Search search={search} />
          {/* <Homepage /> */}
          <Dexnia_homepage />
        </Route>
        {/* ) : ( */}
        <Route path="/search/:searchValue">
          <Header1 search={search} initialize={initialize} />
          <Results courses={courses} />
        </Route>
        {/* )} */}
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
